<template>
  <div class="container-fluid newUser">
    <LoaderComponent v-if="loading" />
    <div class="container list">
        <List v-if="users.length > 0" :fields="{}" :type="'users'" :numberpages='10' :updated.sync="updated" :title="'Lista de Usuários'"/>
    </div>
    <div class="container forms">
      <Form :fields="fields" :config="configs" :clientList="clientList" :type="'users'" :button="button" @recharge="recharge" />
      
    </div>
  </div>
</template>
<script type="text/javascript">
import { NewuserMixin } from "./Newuser.mixin.js";
import Form from "../../components/ui/form/form";
import List from "../../components/ui/list/list";
import LoaderComponent from "../../components/ui/loader/loader";

export default {
  props: {},
  components: {
    List,
    Form,
    LoaderComponent
  },
  name: "Newuser",
  mixins: [NewuserMixin],
};
</script>
<style src="./Newuser.less" lang="less" />
