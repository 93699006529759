import { Newuser } from '../../factories/staticFormsfactory'
import { Users } from '../../factories/staticListsfactory'
import { getUsers } from '../../services/user.service';
import { getClients } from "../../services/client.services";
export const NewuserMixin = {
    data() {
        return {
            paginated: {},
            fields: [],
            configs: {},
            button: {},
            users: [],
            clientList: [],
            updated: '',
            loading: true

        };
    },
    created() {

        this.pushClients()
        this.createFields()
        this.createList()


    },
    mounted() {
        this.usuario()

    },
    watch: {

    },
    computed: {


    },
    methods: {
        pushClients() {
            this.loading = true
            getClients()
                .then(res => {
                    let data = JSON.parse(res.data.data)

                    data.clients.forEach(el => {
                        this.clientList.push({
                            label: el['client_name'].value,
                            value: el['client_name'].value
                        })
                    })


                    this.loading = false

                })
                .catch((e) => {
                    this.loading = false
                    this.$tokentest(e.response.status)
                })
        },
        usuario() {
            this.loading = true
            getUsers()
                .then((res) => {
                    this.loading = false
                    this.users = JSON.parse(res.data.data)
                    this.$forceUpdate()


                })
                .catch((e) => {
                    this.loading = false
                    this.$tokentest(e.response.status)
                })
        },
        createList() {
            let list = new Users()
            
            this.paginated = list.paginateFields(list.fieldsList, 1, 5)
        },

        createFields() {

            let form = new Newuser(this.clientList);

            this.configs.endpoint = form.endpoint
            this.configs.method = form.method
            this.fields = form.fields
            console.log(this.fields)
            this.button = form.button
        },
        recharge(value) {

            this.updated = value
        }

    }

};